<template>
  <div class="catalog">

    <q-layout view="hHh LpR lff" class="bg-white">
      <q-header elevated height-hint="98">
        <q-toolbar class="toolbar_catalog bg-verde justify-between">
          <!-- BOTÃO DE ACIONAMENTO DO DRAWER -->
          <div v-show="isMobile || isTablet" class="btnDrawer column justify-center">
            <q-btn round flat size="15px" color="white" icon="menu" @click="drawer = !drawer" />
          </div>

          <div class="row justify-between full-width">
            <!-- LOGO DA LOJA -->
            <div class="LEFT row text-h5" v-show="!isMobile">
              <q-img
                :src="logoImg"
                width="105px"
                height="105px"
                spinner-color="primary"
                spinner-size="82px"
              />
            </div>

            <!-- NOME DA LOJA E CAMPO DE PESQUISA -->
            <div class="CENTER column justify-center q-gutter-x-sm">
              <div v-show="!isMobile" class="text-center text-bold text-italic text-h5 q-mb-md">
                {{ enterprise }}
              </div>
              <div class="column justify-center">
                <div class="sizeSearch column justify-center">
                  <q-input
                    v-model="search"
                    bg-color="grey-3"
                    type="text"
                    :class="{'text-caption q-ml-lg': isMobile}"
                    class="text-h6"
                    placeholder="Buscar Produto"
                    @change="filterTimes(imagens)"
                    @blur="showSearch()"
                    rounded
                    outlined
                  >
                    <template v-slot:append>
                      <q-icon name="search" color="white" class="iconSearch bg-verde" />
                    </template>
                  </q-input>
                </div>
              </div>
            </div>

            <!-- CARRINHO DE COMPRA - DESKTOP -->
            <div v-if="!isMobile" class="RIGHT row q-gutter-x-sm q-mx-md" style="width: 50px">
              <div class="column justify-center">
                <q-btn
                  icon="shopping_cart"
                  class="btnDrawer q-px-sm"
                  text-color="white"
                  size="25px"
                  @click="onCartShop()"
                >
                  <q-badge rounded color="red" floating class="text-body2 text-bold">
                    {{ cartShopping.length }}
                  </q-badge>
                </q-btn>
              </div>
            </div>

            <!-- CARRINHO DE COMPRA - MOBILE -->
            <div v-else class="RIGHT row q-mx-sm" style="width: 40px">
              <div class="column justify-center">
                <q-btn
                  icon="shopping_cart"
                  class="btnDrawer q-px-xs"
                  text-color="white"
                  size="15px"
                  @click="onCartShop()"
                >
                  <q-badge rounded color="red" floating class="text-caption text-bold">
                    {{ cartShopping.length }}
                  </q-badge>
                </q-btn>
              </div>
            </div>

          </div>
        </q-toolbar>
      </q-header>

      <q-drawer
        v-model="drawer"
        show-if-above
        :width="200"
        :breakpoint="180"
        elevated
        class="bgDrawer text-black"
      >
        <q-scroll-area class="fit">
            <!-- SE NÃO HOUVER SUBNIVEL ELE EXIBIRÁ O MENU PRINCIPAL -->
            <q-list v-if="!isSubLevel" class="q-gutter-y-sm q-mt-md">
            <!-- <q-list class="q-gutter-y-sm q-mt-md"> -->
              <template v-for="menuItem in categorias" :key="menuItem">
                <q-item
                  class="bgItemDrawer"
                  clickable
                  v-ripple
                  @click="onMenuFirstLevel(menuItem)"
                  >
                  <!-- @click="onCategory(menuItem, 0)" -->
                  <q-item-section>
                    <div class="q-pl-xs text-bold text-left">
                      {{ String(menuItem).toUpperCase() }}
                    </div>
                  </q-item-section>
                </q-item>
              </template>
            </q-list>

            <!-- SE HOUVER SUBNIVEL ELE EXIBIRÁ O NOVO MENU -->
            <q-list v-else class="q-gutter-y-sm q-mt-md">
              <div class="fit">
                <q-btn
                  class="fit"
                  flat
                  dense
                  icon="reply"
                  label="Menu Principal"
                  @click="onClickBack(isSubLevel = false)"
                />
              </div>
              <template v-for="menu in folders" :key="menu">
                <q-item
                  class="bgItemDrawer"
                  clickable
                  v-ripple
                  @click="onCategorySubLevel(menu.menus)"
                  >
                  <!-- @click="onCategorySubLevel(menu.menus)" -->
                  <q-item-section>
                    <div class="q-pl-xs text-bold text-left">
                      {{ String(menu.menus).toUpperCase() }}
                    </div>
                  </q-item-section>
                </q-item>
              </template>
            </q-list>
        </q-scroll-area>
      </q-drawer>

      <q-page-container>
        <div v-show="startScreen" class="column justify-center items-center">
          <q-img
            :src="logoImg"
            spinner-color="primary"
            spinner-size="82px"
            width="300px"
            height="300px"
          />
        </div>
        <q-page class="pageMain full-width" v-show="!startScreen">
          <div class="row justify-between items-center q-py-sm q-px-md text-bold">
            <q-breadcrumbs>
              <q-breadcrumbs-el label="Home" />
              <q-breadcrumbs-el :label="firstLevel" />
              <q-breadcrumbs-el v-show="secondLevel !== null" :label="secondLevel" />
            </q-breadcrumbs>
            <q-select
              v-model="perPage"
              :options="[10, 15, 20, 25, 50]"
              label="Produtos por Página"
              outlined
              dense
              style="width: 180px"
            />
          </div>

          <!-- VERSÃO DESKTOP -->
          <q-scroll-area v-if="!isMobile" id="scroll-body">
            <div class="col" v-if="renderCatalog">
              <div class="row justify-center">
                <div v-for="(prod, index) in paginatedData" :key="index">
                  <div
                    class="card column items-center q-mx-xs relative-position"
                    style="width: 240px"
                    @click="onCard(prod)"
                  >
                    <!-- IMAGEM -->
                    <q-img
                      :src="prod.url"
                      width="140px"
                      height="140px"
                      spinner-color="primary"
                      spinner-size="82px"
                      style="border-radius: 10px"
                    />
  
                    <div class="absolute-top-right" v-show="prod.freeShipping">
                      <q-badge
                        color="orange"
                        text-color="white"
                        label="Frete Grátis"
                        class="q-px-md"
                      />
                    </div>
  
                    <!-- DESCRIÇÕES -->
                    <div v-show="drawerOpened" class="column justify-center q-pl-sm">
                      <div class="title text-subtitle1 text-bold">
                        {{ String(prod.name).split('_')[0] }}
                      </div>
                      <div class="subtitle text-body2 text-italic q-mb-sm">
                        {{ String(prod.name).split('_')[1].replace('.jpg', '').replace('.png', '') }}
                      </div>
                    </div>
  
                    <!-- PREÇOS -->
                    <div v-show="drawerOpened" class="row items-center q-pl-sm q-mb-sm">
                      <div
                        class="text-subtitle1 text-bold text-black"
                        :class="{'text-body2 text-grey text-italic text-strike': prod.pricePromotional}"
                      >
                        {{ `R$ ${prod.priceSales}` }}
                      </div>
                      <div
                        v-show="prod.pricePromotional !== 0"
                        class="text-subtitle1 text-bold text-black q-ml-md"
                      >
                        {{ `R$ ${prod.pricePromotional}` }}
                      </div>
                    </div>
  
                    <!-- BOTÃO -->
                    <!-- <div>
                      <q-btn
                        rounded
                        glossy
                        no-caps
                        label="Ver Mais"
                        class="q-px-xl text-bold text-body2"
                        id="btnVerMais"
                        @click="onCard(prod)"
                      />
                    </div> -->
                  </div>
                </div>
              </div>

              <div class="q-px-md row justify-start q-my-md">
                <q-btn-group>
                  <q-btn glossy no-caps dense id="btnVerMais" class="q-px-sm" label="Anterior" @click="onBackPage" />
                  <q-btn
                    v-for="item in Math.ceil(timesFilter.length / perPage)"
                    :key="item.index"
                    glossy no-caps dense id="btnVerMais"
                    class="q-px-md"
                    :label="item"
                    @click="onGoToPage(item)"
                  />
                  <q-btn glossy no-caps dense id="btnVerMais" class="q-px-sm" label="Próxima" @click="onNextPage" />
                </q-btn-group>
              </div>

            </div>
            <div v-else>
              <div
                class="text-h4"
              >Sem produtos para exibir nesta Categoria...</div>
            </div>
          </q-scroll-area>

          <!-- VERSÃO MOBILE -->
          <q-scroll-area v-else id="scroll-body">
            <div class="col">
              <div class="col" v-if="renderCatalog">
                <div class="row justify-center full-width">
                  <div v-for="(item, index) in paginatedData" :key="index" class="row justify-center">
                    <div class="cardMobil row justify-center q-ma-sm relative-position" @click="onCard(item)" style="width: 160px">
                      <!-- IMAGEM -->
                      <q-img
                        :src="item.url"
                        width="100px"
                        height="100px"
                        spinner-color="primary"
                        spinner-size="82px"
                        style="border-radius: 10px"
                      />
    
                      <div class="absolute-top-right" v-show="item.freeShipping">
                        <q-badge
                          color="orange"
                          text-color="white"
                          label="Frete Grátis"
                          class="q-px-md"
                        />
                      </div>
    
                      <!-- DESCRIÇÕES -->
                      <div v-show="drawerOpened" class="column justify-center">
                        <div class="title text-subtitle1 text-bold" style="width: 145px">
                          {{ String(item.name).split('_')[0] }}
                        </div>
                        <div class="subtitle text-body2 text-italic q-mb-sm" style="width: 145px">
                          {{ String(item.name).split('_')[1].replace('.jpg', '').replace('.png', '') }}
                        </div>
                      </div>
    
                      <!-- PREÇOS -->
                      <div v-show="drawerOpened" class="column justify-center q-mb-sm">
                        <div
                          class="text-subtitle1 text-bold text-black text-center"
                          :class="{'text-body2 text-grey text-italic text-strike': item.pricePromotional}"
                        >
                          {{ `R$ ${item.priceSales}` }}
                        </div>
                        <div
                          v-show="item.pricePromotional !== 0"
                          class="text-subtitle1 text-bold text-black text-center"
                        >
                          {{ `R$ ${item.pricePromotional}` }}
                        </div>
                      </div>
    
                      <!-- BOTÃO -->
                      <!-- <div>
                        <q-btn
                          rounded
                          glossy
                          no-caps
                          label="Ver Mais"
                          class="text-bold text-body2 q-px-xl"
                          id="btnVerMais"
                          @click="onCard(item)"
                        />
                      </div> -->
                    </div>
                  </div>
                </div>
  
                <div class="q-px-md row justify-start q-my-md q-mb-xl">
                  <q-btn-group class="btnPagination">
                    <q-btn glossy no-caps dense id="btnVerMais" class="q-px-sm" label="Anterior" @click="onBackPage" />
                    <div class="wrap">
                      <q-btn
                        v-for="item in Math.ceil(timesFilter.length / perPage)"
                        :key="item.index"
                        glossy no-caps dense id="btnVerMais"
                        class="q-px-md"
                        :label="item"
                        @click="onGoToPage(item)"
                      />
                    </div>
                    <q-btn glossy no-caps dense id="btnVerMais" class="q-px-sm" label="Próxima" @click="onNextPage" />
                  </q-btn-group>
                </div>
  
              </div>
              <div v-else>
                <div
                  :class="{'text-h6': isMobile, 'text-h4': !isMobile}"
                >Sem produtos para exibir nesta Categoria...</div>
              </div>
            </div>
          </q-scroll-area>

            <!-- FLOATING BUTTON WHATSAPP -->
            <div v-show="showWhats">
              <q-icon
                class="iconeWhats cursor-pointer bg-green"
                name="fab fa-whatsapp"
                color="white"
                size="40px"
                @click="onRedirectWhats"
              />
            </div>

        </q-page>
      </q-page-container>
    </q-layout>

    <!-- MODAL CARDS -->
    <q-dialog v-model="modalCard" full-width>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div v-if="detCard.category" class="title text-h5 text-bold text-center q-mb-md full-width">
          DETALHES DO PRODUTO
        </div>
        <div v-else class="title text-h5 text-bold text-center q-mb-md full-width">
          DETALHES DO PRODUTO
        </div>

        <q-separator spaced size="5px" />
        
        <!-- VISÃO MOBILE -->
        <q-scroll-area v-if="isMobile" style="width: 100%; height: 60vh;">
          <div class="q-gutter-y-sm column q-mt-sm">
            <div class="full-width col-6 row justify-center">
              <q-img
                :src="detCard.url"
                style="border-radius: 15px;"
                width="90%"
              />
            </div>
            <div class="col-6">
              <div class="text-center text-h4">
                {{ `${String(detCard.name).split('_')[0]}` }}
              </div>
              <div class="text-center text-h6">
                {{ `${String(detCard.name).split('_')[1].replace('.jpg', '')}` }}
              </div>
              <div v-show="drawerOpened" class="row items-center justify-center q-pl-sm q-mb-sm">
                <div
                  class="text-subtitle1 text-bold text-black"
                  :class="{'text-body2 text-grey text-italic text-strike': detCard.pricePromotional}"
                >
                  {{ `R$ ${detCard.priceSales}` }}
                </div>
                <div
                  v-show="detCard.pricePromotional !== 0"
                  class="text-subtitle1 text-bold text-black q-ml-md"
                >
                  {{ `R$ ${detCard.pricePromotional}` }}
                </div>
              </div>

              <q-separator spaced size="5px" />

              <div class="q-mt-xl">
                <q-select class="q-mt-lg" v-model="size" v-if="isChildren" :options="optSizeChildren" label="Tamanho" outlined dense />
                <q-select class="q-mt-lg" v-model="size" v-else :options="onSize" label="Tamanho" outlined dense />
                <q-input class="q-mt-lg" v-model="custom" placeholder="Nome e Número" type="text" label="Personalizações (Opcional)" outlined dense />
                <q-input class="q-mt-lg" v-model="qtdade" placeholder="0" type="number" label="Quantidade" outlined dense />
              </div>

              <div class="row justify-center q-pt-xl">
                <q-btn outline no-caps color="negative" label="Cancelar" @click="modalCard = false" />
                <div class="q-mx-md" />
                <q-btn outline no-caps color="primary" label="Adicionar Carrinho" @click="onAddCart(detCard)" />
              </div>
            </div>
          </div>
        </q-scroll-area>

        <!-- VISÃO DESKTOP -->
        <q-scroll-area v-else style="width: 100%; height: 65vh;">
          <div class="q-gutter-y-sm row q-mt-sm">
            <div v-if="detCard.category === 'Qualidade Produtos'" class="justify-center full-width">
              <q-video
                :src="detCard.url"
                style="border-radius: 15px; height: 350px; width: auto;"
              />
              <div class="row justify-center q-mt-md">
                <q-btn outline no-caps color="negative" label="Cancelar" @click="modalCard = false" />
              </div>
            </div>

            <div v-else class="row full-width">
              <div class="col-6 row justify-center">
                <q-img
                  :src="detCard.url"
                  style="border-radius: 15px;"
                  height="400px"
                  width="350px"
                />
              </div>
              <div class="col-6">
                <div class="text-center text-h5">
                  {{ `${String(detCard.name).split('_')[0]}` }}
                </div>
                <div class="text-center text-subtitle2">
                  {{ `${String(detCard.name).split('_')[1].replace('.jpg', '')}` }}
                </div>
                <div v-show="drawerOpened" class="row items-center justify-center q-pl-sm q-mb-sm">
                  <div
                    class="text-subtitle1 text-bold text-black"
                    :class="{'text-body2 text-grey text-italic text-strike': detCard.pricePromotional}"
                  >
                    {{ `R$ ${detCard.priceSales}` }}
                  </div>
                  <div
                    v-show="detCard.pricePromotional !== 0"
                    class="text-subtitle1 text-bold text-black q-ml-md"
                  >
                    {{ `R$ ${detCard.pricePromotional}` }}
                  </div>
                </div>

                <q-separator spaced size="5px" />

                <div class="q-mt-xl">
                  <q-select class="q-mt-lg" v-model="size" v-if="isChildren" :options="optSizeChildren" label="Tamanho" outlined dense />
                  <q-select class="q-mt-lg" v-model="size" v-else :options="onSize" label="Tamanho" outlined dense />
                  <q-input class="q-mt-lg" v-model="custom" placeholder="Nome e Número" type="text" label="Personalizações (Opcional)" outlined dense />
                  <q-input class="q-mt-lg" v-model="qtdade" placeholder="0" type="number" label="Quantidade" outlined dense />
                </div>

                <div class="row justify-center q-pt-xl">
                  <q-btn outline no-caps color="negative" label="Cancelar" @click="modalCard = false" />
                  <div class="q-mx-md" />
                  <q-btn outline caps color="primary" label="Adicionar ao Carrinho" @click="onAddCart(detCard)" />
                </div>
            </div>
            </div>
          </div>
        </q-scroll-area>
      </div>
    </q-dialog>

    <!-- MODAL CART SHOPPING -->
    <q-dialog v-model="modalCartShop" full-width persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div class="titlebar row items-center justify-between q-mb-md">
          <div class="title text-h6 text-bold text-center q-ml-sm">
            CARRINHO DE COMPRA
          </div>
          <div>
            <q-btn class="q-mr-md" color="negative" label="X" @click="onCloseModalCartShopping" />
          </div>
        </div>

        <q-separator spaced size="5px" />
        
        <!-- VISÃO MOBILE -->
          <div v-if="isMobile" class="q-gutter-y-sm q-mt-sm">
            <div class="col-6 justify-center">
              <div class="text-h6 row justify-center">
                <div class="items-center">
                  <div class="text-bold">Lista de Compra</div>
                  <q-btn class="full-width text-red" flat no-caps label="Limpar Carrinho" @click="onClearCartShopping" />
                </div>
              </div>
              <div class="row">
                <!-- <q-scroll-area style="width: 100%;"> -->
                  <div class="gradeTable full-width row justify-center items-center q-my-sm q-pr-md" v-for="(cart, index) in cartShopping" :key="index">
                    <q-img
                      :src="cart.url"
                      class="q-mt-sm"
                      width="60px"
                      height="60px"
                    />
                    <div class="column items-center">
                      <div class="text-subtitle1 text-center">
                        {{ `${cart.team} (${cart.model}): ${cart.gender} - ${cart.size} x ${cart.qtdade}` }}
                      </div>

                      <div>
                        <q-btn round flat color="red" icon="highlight_off" @click="removeItem(index)" />
                      </div>
                    </div>
                  </div>
                <!-- </q-scroll-area> -->
              </div>
            </div>
            <div class="col-6">
              <div class="text-h6 text-center q-ml-lg">
                Cadastro do Cliente
              </div>
              <div class="q-gutter-y-md q-mt-md q-px-lg">
                <q-input outlined v-model="client" type="text" label="Nome Completo" />
                <q-input outlined v-model="teamHearth" type="text" label="Time do Coração" />
                <q-input outlined v-model="address" type="text" label="Endereço" />
                <q-input outlined v-model="phone" type="text" label="Celular" />
              </div>
            
              <div class="row justify-center">
                <q-btn class="q-mt-md" outline caps color="primary" label="Solicitar Orçamento" @click="onSentOrder" />
              </div>
            </div>
          </div>

        <!-- VISÃO DESKTOP -->
          <div v-else class="q-gutter-y-sm row q-mt-sm">
            <div class="col-6 justify-center">
              <div class="text-h6 row justify-center q-ml-lg">
                <div class="items-center">
                  <div class="text-bold">Lista de Compra</div>
                  <q-btn class="full-width text-red" flat no-caps label="Limpar Carrinho" @click="onClearCartShopping" />
                </div>
              </div>
              <div class="row">
                <div class="text-h6 text-bold">
                  {{ `Valor Total do Pedido: R$ ${parseFloat(cartShopping[(cartShopping.length - 1)].valueTotal).toFixed(2)}` }}
                </div>
                <q-scroll-area style="width: 100%; height: 50vh;">
                  <div class="bAs full-width row justify-between items-center q-my-sm q-pr-md" v-for="(cart, index) in cartShopping" :key="index">
                    <q-img
                      :src="cart.url"
                      width="60px"
                      height="60px"
                    />

                    <div style="width: calc(100% - 120px)">
                      <div class="text-subtitle1" >
                        {{ `${cart.team} (${cart.model}): ${cart.gender} - ${cart.size}`}}
                      </div>
                      <div class="text-subtitle1 text-bold">
                        {{ `${cart.qtdade} x R$ ${cart.price} = R$ ${parseFloat(cart.qtdade * cart.price).toFixed(2)}`}}
                      </div>
                    </div>

                    <div class="justify-right">
                      <q-btn round flat color="red" icon="highlight_off" @click="removeItem(index)" />
                    </div>
                  </div>
                </q-scroll-area>
              </div>
            </div>
            <div class="col-6">
              <div class="text-h6 text-center q-ml-lg">
                Cadastro do Cliente
              </div>
              <div class="q-gutter-y-md q-mt-md q-px-lg">
                <q-input outlined v-model="client" type="text" label="Nome Completo" />
                <q-input outlined v-model="teamHearth" type="text" label="Time do Coração" />
                <q-input outlined v-model="address" type="text" label="Endereço" />
                <q-input outlined v-model="phone" type="text" label="Celular" />
              </div>
            
              <div class="row justify-center">
                <q-btn class="q-mt-md" outline caps color="primary" label="Solicitar Orçamento" @click="onSentOrder" />
              </div>
            </div>
          </div>

      </div>
    </q-dialog>
  </div>
</template>

<script>
  import { supabase } from '../../supabase';
  import { Dialog } from 'quasar';
  import DateNow from '../../helpers/dateNow';
  import { defaultColor } from '../../helpers/defaultColor';
  import { mapActions } from 'vuex';

  export default {
    data() {
      return {
        startScreen: true,
        enterprise: null,
        slug: null,
        standColor: null,
        drawer: true,
        categorias: [],
        imagens: [],
        detCard: [],
        modalCard: false,
        league: null,
        custom: null,
        size: null,
        model: 'Masculino',
        qtdade: null,
        cartShopping: [],
        valueTotal: 0,
        modalCartShop: false,
        client: null,
        teamHearth: null,
        address: null,
        phone: null,
        logoImg: null,
        timesFilter: [],
        search: null,
        // btnSearch: true,
        optSizeChildren: [
          '14 (2 - 3 anos)',
          '16 (3 - 4 anos)',
          '18 (4 - 5 anos)',
          '20 (5 - 6 anos)',
          '22 (6 - 7 anos)',
          '24 (8 - 9 anos)',
          '26 (10 - 11 anos)',
          '28 (12 - 13 anos)',
        ],
        contador: 0,
        tempStatus: true,

        isSubLevel: false,
        previousFolder: null,
        folders: [],

        firstLevel: null,
        secondLevel: null,
        renderCatalog: false,

        msgWhats: null,
        numWhats: null,
        showWhats: false,
        phoneWhats: null,

        page: 1,
        perPage: 10
      }
    },
    computed: {
      isAdmin() {
        if(this.enterprise === 'ADMIN') {
          return true;
        } else {
          return false;
        }
      },
      isMobile() {
        return this.$q.screen.xs;
      },
      isTablet() {
        return this.$q.screen.sm;
      },
      isChildren() {
        if(this.model === 'Infantil') {
          return true
        } else {
          return false
        }
      },
      drawerOpened() {
        if(this.isTablet || this.isMobile) {
          if(this.drawer) {
            return true;
            // return false;
          }
        } else {
          return true;
        }

        return true;
      },
      onSize() {
        let size;
        if(this.firstLevel === 'Tenis' || this.firstLevel === 'Chuteiras') {
          size = ['37', '38', '39', '40', '41', '42', '43', '44']
        } else if(this.firstLevel === 'Luvas de Goleiro') {
          size = ['8', '9', '10']
        } else {
          size = ['P', 'M', 'G', 'GG', '2GG', '3GG']
        }
        return size;
      },
      paginatedData() {
        const arrProducts = [];
        this.timesFilter.forEach(prod => {
          if(parseFloat(prod.priceSales) > 0)
            arrProducts.push(prod);
        })

        return arrProducts.slice((this.page - 1) * this.perPage, this.page * this.perPage)
      }
    },
    methods: {
      ...mapActions('Functions', [ 'setImagesByMenu', 'sentWhats', 'sentContact' ]),
      onBackPage() {
        if(this.page !== 1) {
          this.page -= 1;
        }
      },
      onNextPage() {
        if(this.page !== Math.ceil(this.timesFilter.length / this.perPage)) {
          this.page += 1;
        }
      },
      onGoToPage(numPage) {
        this.page = numPage;
      },
      async onMenuFirstLevel(opt) {
        this.search = '';

        //~~> opt Null ou Undefined significa que não foi clicado em nenhuma opção e
        //    portanto deverá ser carregado o Menu Principal
        if(opt === null || opt === undefined) {
          const mainMenu = await supabase
            .from('GE_SETTINGS')
              .select('GE_CATEGORYS')
                .eq('GE_ENTERPRISE', this.enterprise)
        
          const mainSort = mainMenu.data[0].GE_CATEGORYS.sort();
          const mainReverse = mainSort.reverse();

          this.categorias = mainReverse;
        }

        //~~> Verificando se no Menu haverá SubNíveis
        const { data } = await supabase
          .rpc('itemsdrawer', {
            menu: String(opt).toLowerCase()
          })

        //~~> Gravando a Cardinalidade (ela que me indica se há SubNíveis):
        //==> 2: Não há SubMenus;
        //==> 3: Há SubMenus;
        let sizeMenu = data[0]?.cardinality

        if(sizeMenu === 3) {
          this.firstLevel = data[0].path_tokens[0];
          this.secondLevel = data[0].path_tokens[1];

          this.isSubLevel = true
          var { data: menus } = await supabase
            .rpc('getfolderssubmenu', {
              menu: String(opt).toLowerCase()
            })

          if(menus) {
            this.folders = menus
          }
        }

        if(sizeMenu === 2) {
          this.firstLevel = data[0].path_tokens[0];
          this.secondLevel = null
          this.startScreen = false

          var { data: dataDB } = await supabase
            .from('GE_SETTINGS')
              .select(String(opt).toLowerCase().replace(' ', ''))
                .eq('GE_ENTERPRISE', this.enterprise)
          
          try {
            var images = await this.setImagesByMenu({
              opt: this.firstLevel,
              data: dataDB
            })
            
            this.renderCatalog = true
          } catch (err) {
            this.renderCatalog = false
          }

          this.imagens = images
          this.timesFilter = images
        }

      },
      async onCategorySubLevel(menu) {
        this.search = '';
        this.startScreen = false;
        this.secondLevel = menu;

        const nameMenu = `${String(this.firstLevel).toLowerCase().replace(' ', '')}${String(this.secondLevel).toLowerCase().replace(' ', '')}`;

        const { data } = await supabase
            .from('GE_SETTINGS')
              .select(nameMenu)
                .eq('GE_ENTERPRISE', this.enterprise)

        try {
          var images = await this.setImagesByMenu({
            opt: `${this.firstLevel}/${this.secondLevel}`,
            data: data
          })

          this.renderCatalog = true
        } catch (err) {
          this.renderCatalog = false
        }

        this.imagens = images
        this.timesFilter = images
      },
      onCard(infos) {
        if(String(infos.name).includes('Feminina')) {
          this.model = 'Feminina';
        } else if(String(infos.name).includes('Infantil')) {
          this.model = 'Infantil';
        } else {
          this.model = 'Masculino';
        }

        this.modalCard = true;
        this.detCard = infos;
      },
      onAddCart(data) {

        if(!this.validFormItens()) {
          return ''
        }

        var priceSales = 0
        if(data.pricePromotional !== 0) {
          priceSales = parseFloat(String(data.pricePromotional).replace(',', '.')).toFixed(2)
        } else {
          priceSales = parseFloat(String(data.priceSales).replace(',', '.')).toFixed(2)
        }

        var total = parseFloat(priceSales) * parseInt(this.qtdade)
        this.valueTotal = (parseFloat(this.valueTotal) * 1) + (parseFloat(total).toFixed(2) * 1)

        let infos = {
          url: data.url,
          league: data.category,
          team: String(data.name).split('_')[0],
          model: String(data.name).split('_')[1].replace('.jpg', ''),
          gender: this.model,
          custom: this.custom === null ? '' : this.custom,
          size: this.size,
          qtdade: parseInt(this.qtdade),
          levels: this.secondLevel === null ? `${this.firstLevel}` : `${this.firstLevel}/${this.secondLevel}`,
          price: priceSales,
          valueTotal: this.valueTotal
        }

        this.cartShopping.push(infos)

        this.resetFormItens();
        this.modalCard = false;
      },
      resetFormItens() {
        this.custom = null,
        this.size = null,
        this.qtdade = 0
      },
      validFormItens() {
        if(this.size == null || this.size == '') {
          Dialog.create({
            title: 'Erro ao escolher o Tamanho',
            message: 'Favor selecionar o TAMANHO desejado.'
          })

          return false;
        }

        if(this.qtdade <= 0) {
          Dialog.create({
            title: 'Erro ao informar a Quantidade',
            message: 'Favor informar a QUANTIDADE desejada.'
          })

          return false;
        }

        return true;

      },
      onCartShop() {
        this.modalCartShop = true;
      },
      validFormClient() {
        if(this.client == null || this.client == '') {
          Dialog.create({
            title: 'Erro Cliente',
            message: 'Favor informar o nome do cliente completo.'
          })

          return false;
        }
        
        if(this.address == null || this.address == '') {
          Dialog.create({
            title: 'Erro Endereço',
            message: 'Favor informar o endereço completo.'
          })

          return false;
        }
        
        if(this.phone == null || this.phone == '') {
          Dialog.create({
            title: 'Erro Celular',
            message: 'Favor informar o Celular com DDD.'
          })

          return false;
        }
        
        if(this.cartShopping.length <= 0) {
          Dialog.create({
            title: 'Carrinho Vazio',
            message: 'Favor selecionar camisas que deseja receber orçamento.'
          })

          return false;
        }

        return true;

      },
      async onSentOrder() {
        if(!this.validFormClient()) {
          return ''
        }

        const enterprise = this.$route.params.slug
        const dt = DateNow(Date(Date.now))

        const { data, error } = await supabase
          .from('GE_PRE_SALE')
          .insert({
            GE_CLIENT: this.client,
            GE_TEAM_HEARTH: this.teamHearth,
            GE_ADDRESS: this.address,
            GE_PHONE: this.phone,
            GE_ITEMS: this.cartShopping,
            GE_STATUS: 'PENDENTE',
            GE_ENTERPRISE: enterprise,
            GE_DATE_ISSUE: dt,
            GE_LOGO: this.logoImg,
            DELETED: ' '
          })

        if(error) { console.error(error.message) }
        if(data) {

          var bodyWhats = '';
          this.cartShopping.forEach(item => {
            bodyWhats += `
              _Liga_: ${item.levels}
              _Time_: ${item.team}
              _Modelo_: ${item.model}
              _Sexo_: ${item.gender}
              _Personalização_: ${item.custom === null ? '-' : item.custom}
              _Tamanho_: ${item.size}
              _Qtdade_: ${item.qtdade}
              _Valor_: ${item.price}
              __________________________
            `
          })

          //~~> this.phone = É o telefone do Cliente
          //~~> phoneWhats = É o telefone do Lojista
          let num = this.corrigirNumWhats(this.phoneWhats)
          if(String(num).substring(0, 1) === '+') {
            num = String(num).replace('+', '')
          } else {
            num = '55' + num
          }

          await this.sentWhats({
            phone: num,
            body: `
            Bom dia *${this.enterprise}*,
            Você tem um novo orçamento de:
            *${this.client}!*

            ${bodyWhats}

            *Valor Total Pedido: R$ ${parseFloat(this.cartShopping[(this.cartShopping.length - 1)].valueTotal).toFixed(2)}*

            Entre em contato com o cliente através
            do número: *${this.phone}*

            Atenciosamente,
            Equipe Fature Sistemas!
            `
          })

          // await this.sentContact({
          //   phoneStore: this.phoneWhats,
          //   contactName: this.client,
          //   phoneClient: this.phone
          // })

          this.modalCartShop = false; //~> Fechando o Modal do Carrinho de Compras
          this.cartShopping.splice(0, this.cartShopping.length); //~> Limpando o Carrinho de Compras

          Dialog.create({
            title: `<p class="text-h6 text-bold">Obrigado pela preferência</p>`,
            message: '<p>Seu pedido de orçamento foi recebido com sucesso! Em breve um de nossos vendedores entrará em contato.<br><br>Obrigado!!!</p>',
            persistent: true,
            html:true,
            cancel: false,
            ok: 'OK'
          })
        }
      },
      onCloseModalCartShopping() {
        this.modalCartShop = false;

        //Verificar se há necessidade de resetar formulário!!!
      },
      removeItem(index) {
        Dialog.create({
          title: '<p class="text-h6 text-bold">Removendo Item do Carrinho</p>',
          message: '<p>Deseja realmente <b class="text-red">REMOVER</b> este item?</p>',
          persistent: true,
          html:true,
          cancel: "Não",
          ok: "Sim"
        }).onOk(() => {
          this.cartShopping.splice(index, 1)
        })
      },
      onClearCartShopping() {
        Dialog.create({
          title: '<p class="text-h6 text-bold">Limpar Carrinho de Compra</p>',
          message: '<p>Deseja realmente <b class="text-red">REMOVER</b> todos os itens?</p>',
          persistent: true,
          html:true,
          cancel: 'Não',
          ok: 'Sim'
        }).onOk(() => {
          this.cartShopping.splice(0, this.cartShopping.length)
        })
      },
      filterTimes(elements) {
        const newData = []
        elements.filter(elem => {
          if(String(String(elem.name).toUpperCase()).includes(String(this.search).toUpperCase())) {
            newData.push(elem)
          }
        })

        this.timesFilter = newData;
      },
      showSearch() {
        this.btnSearch = !this.btnSearch;
      },
      corrigirNumWhats(num) {
        let numero = String(num).replace(' ', '')
        numero = String(numero).replace(' ', '')
        numero = String(numero).replace(' ', '')
        numero = String(numero).replace(' ', '')
        numero = String(numero).replace('(', '')
        numero = String(numero).replace('(', '')
        numero = String(numero).replace(')', '')
        numero = String(numero).replace(')', '')
        numero = String(numero).replace('-', '')
        numero = String(numero).replace('-', '')
        numero = String(numero).replace('-', '')

        return numero
      },
      onRedirectWhats() {
        let num = this.corrigirNumWhats(this.numWhats)
        if(String(num).substring(0, 1) === '+') {
          num = String(num).replace('+', '')
        } else {
          num = '55' + num
        }

        window.open(
          `https://api.whatsapp.com/send?phone=${num}&text=${this.msgWhats}`,
          '_blank'
        );
      }
    },
    async created() {
      defaultColor();
      this.slug = this.$route.params.slug

      var deletado;
      await supabase
        .auth
        .signIn({
          email: 'config@gestaoesportes.com.br',
          password: '@dmin2022'
        }
      ).then(async () => {
          const { data: setting } = await supabase
            .from('GE_SETTINGS')
            .select('GE_ENTERPRISE, GE_NUMWHATS, GE_MSGWHATS')
            .match({ 'GE_SLUG': this.slug})
          this.enterprise = setting[0].GE_ENTERPRISE;

          if(setting[0].GE_NUMWHATS === null) {
            this.showWhats = false
          } else if(setting[0].GE_NUMWHATS === '') {
            this.showWhats = false
          } else {
            this.showWhats = true
            this.numWhats = setting[0].GE_NUMWHATS
            this.msgWhats = setting[0].GE_MSGWHATS

            var num = String(this.numWhats)
              .replaceAll(' ', '')
              .replaceAll('-', '')
              .replaceAll('(', '')
              .replaceAll(')', '')

            this.phoneWhats = num
          }

          const { data: perfil } = await supabase
            .from('GE_PERFIL_USERS')
            .select('GE_DEFAULTCOLOR, DELETED')
            .match({ 'GE_ENTERPRISE': this.enterprise })

          if(perfil[0].DELETED === '*')
            deletado = true

          this.standColor = perfil[0].GE_DEFAULTCOLOR;
          localStorage.setItem('defaultColor', this.standColor);
        }
      )

      if(!deletado) {
        await this.onMenuFirstLevel()

        //~>Carregando a Logo
        const enterprise = this.slug + '.png'
        const logo = supabase
          .storage
          .from('logos')
          .getPublicUrl(enterprise)

        this.logoImg = logo.data.publicURL

        this.startScreen = true
        this.isSubLevel = false
        if(this.isMobile || this.isTablet) {
          this.drawer = false
        } else {
          this.drawer = true
        }
      }
    }
  }
</script>

<style lang="scss">
  @import url('../../globais/GlobalStyles.scss');

  .catalog {
  
    .toolbar_catalog {
      height: 120px;

      .bgText {
        background-color: rgba($color: #fff, $alpha: 0.35);
        color: #000;
        padding-inline: 10%;
        border-radius: 8px;
      }

      .btnDrawer {
        background-color: rgba($color: #fff, $alpha: 0.25);
        color: #000;
        border-radius: 20px;
        width: 50px;
        height: 50px;
      }
    }

    .toolbar_cartSale {
      border-top: 5px solid var(--primaryLight);
      background: linear-gradient(90deg, #fff 65%, var(--primary) 90%);
    }

    .stlImg {
      border-radius: 15px;
    }

    .mainCard {
      width: 95%;
    }

    .halfCard {
      width: 50%;
    }

    .category {
      height: 100%;
      border-right: 1px solid gray;
    }

    .cardTime {
      width: 165px;
      background: linear-gradient(120deg, #fff 45%, #bbb);
    }

    .destaque {
      box-shadow: 5px 5px 10px rgb(46, 45, 45);
    }

    .gridBody {
      display:grid;
      grid-template-columns: 50% 50%;
      grid-auto-rows: 170px;
    }

    .pageMain {
      overflow-y: hidden;
    }

    #scroll-body {
      height: calc(100vh - 185px);
    }

    .iconeWhats {
      position: fixed;
      bottom: 20px;
      right: 20px;
      width: 50px;
      height: 50px;
      border-radius: 60px;
      align-items: center;
    }

    #btnVerMais {
      background-color: rgba($color: #000, $alpha: 0.25);
    }

  }


  /* Celulares (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 600px) {
    .title { width: 180px; font-size: 15px; }
    .subtitle { width: 180px; font-size: 15px; }
    .CENTER { width: 180px; }
    .sizeSearch { width: 220px; }
    .iconSearch {
      height: 30px;
      width: 30px;
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  /* Tablets (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .title { width: 180px }
    .subtitle { width: 180px }
    .CENTER { width: 400px }
    .iconSearch {
      height: 35px;
      width: 35px;
      border-radius: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  /* Desktop (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .title { width: 20vw }
    .subtitle { width: 20vw }
    .CENTER { width: 400px }
    .iconSearch {
      height: 40px;
      width: 40px;
      border-radius: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
</style>
