<template>
  <div>
    <WithoutPrice v-if="perfil === 'without'"/>
    <WithPrice v-else />
  </div>
</template>

<script>
import WithoutPrice from "./Catalog_without_price";
import WithPrice from "./Catalog_with_price";
import { supabase } from "../supabase";

export default {
  components: {
    WithoutPrice,
    WithPrice
  },
  data() {
    return {
      perfil: '',
      slug: null
    }
  },
  async created() {
    //~~> CAPTURANDO O SLUG DO CLIENTE
    this.slug = this.$route.params.slug

    //~~> AUTENTICANDO UM USUÁRIO PARA ACESSAR A PLATAFORMA
    const { user } = await supabase
      .auth
      .signIn({
        email: 'config@gestaoesportes.com.br',
        password: '@dmin2022...VamorRumoAVitória'
      })

    //~~> VERIFICANDO QUAL CATÁLOGO CARREGAR (COM OU SEM PREÇOS)
    if(user) {
      const { data, error } = await supabase
        .from('GE_SETTINGS')
        .select('GE_PRICEINCATALOG')
        .eq('GE_SLUG', this.slug)

      console.warn('perfilCatalogo', data)
      
      /**************************************************/
      /*    this.perfil = true  (Catálogo Sem Preço)    */
      /*    this.perfil = false (Catálogo Com Preço)    */
      /**************************************************/
      if(data[0]) {
        this.perfil = data[0]?.GE_PRICEINCATALOG;
        console.warn('perfilCatalogo', data[0].GE_PRICEINCATALOG)
      }

      if(error)
        this.perfil = 'without';
    }
  }
}
</script>

<style lang="scss">

</style>
