export default function dateNow() {
  let hoje = new Date(Date.now())
  let dia = hoje.getDate()
  let mes = hoje.getMonth() + 1
  let ano = hoje.getFullYear()

  mes = String('0').repeat(2 - String(mes).length) + mes
  dia = String('0').repeat(2 - String(dia).length) + dia
  let agora = ano + '-' + mes + '-' + dia;

  return agora;
}
